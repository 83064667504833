var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"main-body-wrapper",attrs:{"id":"bank-form"},on:{"keydown":_vm.handleKeyboardNavigation}},[_vm._m(0),_c('div',{staticClass:"form-wrapper form-company-info"},[_c('div',{staticClass:"container-fluid px-0"},[_c('form',{staticClass:"form-content"},_vm._l((_vm.bankAccounts),function(bankAccount,index){return _c('div',{key:bankAccount.id,ref:`bankAccountSection-${index}`,refInFor:true,staticClass:"bank-account-section",attrs:{"tabindex":"0"}},[(_vm.bankAccounts.length > 1)?_c('div',{staticClass:"account-header",on:{"click":function($event){return _vm.toggleSection(index)}}},[_c('i',{staticClass:"fa fa-chevron-down chevron",class:{ 'chevron-rotated': _vm.isOpen(index) }}),_c('span',{staticClass:"account-label"},[_vm._v(_vm._s(bankAccount.accountLabel || `Account ${index + 1}`))])]):_vm._e(),_c('div',{class:{
              'account-details': _vm.bankAccounts.length > 1,
              'account-details-single': _vm.bankAccounts.length === 1,
              open: _vm.isOpen(index)
            }},[_c('BaseCustomInput',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.nameOnAccount),expression:"modelValidations.nameOnAccount"}],ref:`nameOnAccount-${index}`,refInFor:true,attrs:{"name":`nameOnAccount-${index}`,"required":"","label":"Name on Account","error":_vm.getError(`nameOnAccount-${index}`),"tooltip":"Enter the name as it appears on the bank account."},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.handleEnterKey(`nameOnAccount-${index}`, `accountType-${index}`, $event)}},model:{value:(bankAccount.nameOnAccount),callback:function ($$v) {_vm.$set(bankAccount, "nameOnAccount", $$v)},expression:"bankAccount.nameOnAccount"}}),_c('BaseSelect',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.accountType),expression:"modelValidations.accountType"}],ref:`accountType-${index}`,refInFor:true,attrs:{"name":`accountType-${index}`,"required":"","label":"Account Type","options":_vm.accountTypeOptions,"error":_vm.getError(`accountType-${index}`),"tooltip":"Select the type of bank account (e.g., Checking, Savings)."},on:{"switch-focus":function($event){return _vm.handleEnterKey(
                  `accountType-${index}`,
                  `accountRouting-${index}`,
                  $event
                )},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.handleEnterKey(
                  `accountType-${index}`,
                  `accountRouting-${index}`,
                  $event
                )}},model:{value:(bankAccount.accountType),callback:function ($$v) {_vm.$set(bankAccount, "accountType", $$v)},expression:"bankAccount.accountType"}}),_c('BaseCustomInput',{directives:[{name:"maska",rawName:"v-maska",value:('#########'),expression:"'#########'"},{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.accountRouting),expression:"modelValidations.accountRouting"}],ref:`accountRouting-${index}`,refInFor:true,attrs:{"name":`accountRouting-${index}`,"label":"Routing Number","data-vv-as":"Routing Number","required":"","error":_vm.getError(`accountRouting-${index}`),"tooltip":"A 9-digit number that identifies your bank. You can find it on your checks or bank statements."},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.handleEnterKey(
                  `accountRouting-${index}`,
                  `accountNumber-${index}`,
                  $event
                )}},model:{value:(bankAccount.accountRouting),callback:function ($$v) {_vm.$set(bankAccount, "accountRouting", $$v)},expression:"bankAccount.accountRouting"}}),_c('BaseCustomInput',{directives:[{name:"maska",rawName:"v-maska",value:('###################'),expression:"'###################'"},{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.accountNumber),expression:"modelValidations.accountNumber"}],ref:`accountNumber-${index}`,refInFor:true,attrs:{"name":`accountNumber-${index}`,"label":"Account Number","data-vv-as":"Account Number","required":"","error":_vm.getError(`accountNumber-${index}`),"tooltip":"Your unique bank account number. You can find it on your checks or bank statements."},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.handleEnterKey(
                  `accountNumber-${index}`,
                  `confirmAccountNumber-${index}`,
                  $event
                )}},model:{value:(bankAccount.accountNumber),callback:function ($$v) {_vm.$set(bankAccount, "accountNumber", $$v)},expression:"bankAccount.accountNumber"}}),_c('BaseCustomInput',{directives:[{name:"maska",rawName:"v-maska",value:('###################'),expression:"'###################'"},{name:"validate",rawName:"v-validate",value:({ required: true, confirmed: bankAccount.accountNumber }),expression:"{ required: true, confirmed: bankAccount.accountNumber }"}],ref:`confirmAccountNumber-${index}`,refInFor:true,class:{ 'mb-0': true },staticStyle:{"min-height":"0"},attrs:{"name":`confirmAccountNumber-${index}`,"label":"Confirm Account Number","required":"","error":_vm.getError(`confirmAccountNumber-${index}`)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();_vm.handleEnterKey(
                  `confirmAccountNumber-${index}`,
                  index < _vm.bankAccounts.length - 1
                    ? `nameOnAccount-${parseInt(index) + 1}`
                    : 'submitButton',
                  $event
                )}},model:{value:(bankAccount.confirmAccountNumber),callback:function ($$v) {_vm.$set(bankAccount, "confirmAccountNumber", $$v)},expression:"bankAccount.confirmAccountNumber"}})],1)])}),0),_c('div',{staticClass:"d-flex justify-content-end w-100"},[_c('button',{ref:"submitButton",staticClass:"btn continue-button",attrs:{"disabled":_vm.isSubmitting},on:{"click":_vm.submitForm}},[_vm._v(" "+_vm._s(_vm.isSubmitting ? 'Processing...' : 'Continue')+" ")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-title w-100"},[_c('h1',[_vm._v("Add your business bank account")]),_c('h2',{staticClass:"sub-title"},[_vm._v(" Payouts you earn will be sent to this account. "),_c('i',[_vm._v("If entering multiple accounts, make sure to check the corresponding labels.")])])])
}]

export { render, staticRenderFns }