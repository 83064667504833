<!-- <template>
  <div id="base-layout">
    <div class="content-wrapper">
      <router-view name="Header" />
      <div class="split-screen">
        <div class="default-view">
          <router-view />
        </div>
        <router-view name="SideImage" />
      </div>
    </div>
    <router-view name="Footer" />
  </div>
</template> -->

<template>
  <div id="base-layout">
    <router-view name="Header" />
    <div id="content-view">
      <button v-if="showBackButton" @click.prevent="goBack" class="back-button">
        <i class="fa fa-arrow-left"></i>
      </button>
      <router-view />
    </div>
    <router-view name="Footer" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BaseLayout',
  computed: {
    ...mapGetters({
      shouldOverrideBackButton: 'shouldOverrideBackButton'
    }),
    showBackButton() {
      const noBackRoutes = [
        'GetStarted',
        'Checks',
        'AccountApproved',
        'AccountNotApproved',
        'MobileCodeVerification'
      ];
      return !noBackRoutes.includes(this.$route.name);
    }
  },
  methods: {
    async goBack() {
      console.log('goBack method called');
      if (this.shouldOverrideBackButton) {
        console.log('Overriding back button');
        await this.$store.dispatch('SET_OVERRIDE_BACK_BUTTON', false);
        await this.$store.dispatch('SET_BACK_BUTTON_PRESSED', true);
        return;
      }
      const routes = [
        'MobileCodeVerification',
        'MerchantType',
        'CompanyForm',
        'VerifyOwnership',
        'BankForm',
        'ConfirmProfile'
      ];
      console.log('Current route:', this.$route.name);
      const currentRouteIndex = routes.findIndex(route => route === this.$route.name);
      console.log('Current route index:', currentRouteIndex);
      const previousRouteName =
        currentRouteIndex <= 0 ? 'GetStarted' : routes[currentRouteIndex - 1];
      console.log('Navigating to:', previousRouteName);
      await this.$router.push({ name: previousRouteName });
    }
  }
};
</script>

<style lang="scss">
#base-layout {
  display: flex;
  flex-direction: column;
  flex: 1;

  #content-view {
    position: relative;
    flex: 1;
    max-width: 968px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fdfcfc;
    box-shadow: -3px 0 3px -2px rgba(0, 0, 0, 0.1), 3px 0 3px -2px rgba(0, 0, 0, 0.1);
  }
  .back-button {
    position: absolute;
    top: 20px;
    left: 20px;
    background: none;
    border: none;
    cursor: pointer;

    .fa {
      font-size: 24px;
      color: #333;
    }
  }
}
</style>
