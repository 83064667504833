import axios from 'axios';
import { ClientJS } from 'clientjs';
import { datetimeToString } from '@/helpers/date';

export const beatufyApiErrorMessage = (errorMessage = '') => {
  let message = null;
  const idx = errorMessage.indexOf('ENV');
  if (idx > -1) message = errorMessage.substring(0, idx - 2);
  return message;
};

export const getBrowseInfo = async () => {
  const client = new ClientJS();
  let ipInfo;
  try {
    const response = await axios.get(
      'https://api.ipfind.com/me?auth=24fa667e-a9ec-4054-96c4-6725b4f9c3ce'
    );
    ipInfo = response.data;
  } catch (error) {
    ipInfo = {};
  }

  const browserInfo = {
    userAgent: client.getUserAgent(),
    datetime: datetimeToString(new Date()),
    ip: {
      ipAddress: ipInfo.ip_address,
      country: ipInfo.country,
      city: ipInfo.city
    }
  };
  const fingerprint = client.getCustomFingerprint(browserInfo.userAgent, ipInfo.ip_address);
  browserInfo.fingerprint = fingerprint;
  return browserInfo;
};

export function debounce(func, wait, immediate, context = this) {
  let timeout;

  function debounced(...args) {
    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  }

  debounced.cancel = () => {
    clearTimeout(timeout);
    timeout = null;
  };

  return debounced;
}

import { DateTime } from 'luxon';

/**
 * Deep clones an object or array, handling circular references
 * @param {*} obj - The object to clone
 * @param {Object} [seen] - Internal use for tracking circular references
 * @returns {*} A deep clone of the input
 */
export function improvedStructuredClone(obj) {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  let clone;

  try {
    clone = structuredClone(obj);
  } catch (error) {
    // If structuredClone fails, we'll do a custom clone
    clone = Array.isArray(obj) ? [] : Object.create(Object.getPrototypeOf(obj));
    for (const [key, value] of Object.entries(obj)) {
      clone[key] = value;
    }
  }

  for (const key in obj) {
    if (Object.hasOwn(obj, key)) {
      const value = obj[key];

      if (value instanceof DateTime) {
        clone[key] = DateTime.fromObject(value.toObject());
      } else if (value instanceof RegExp) {
        clone[key] = new RegExp(value.source, value.flags);
        clone[key].lastIndex = value.lastIndex;
      } else if (typeof value === 'function') {
        clone[key] = value; // Functions are not cloned, just referenced
      }
    }
  }

  return clone;
}